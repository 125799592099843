import Head from 'next/head';
import type { GetServerSideProps, InferGetStaticPropsType } from 'next';
import { NextPageWithLayout } from '@/pages/_app';
import React from 'react';
import { AppSettings } from '@/shared/app-common';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';

const MainPage = dynamic(() => import('@/components/MainPage'), { ssr: true });
const Layout = dynamic(() => import('@/layouts'), { ssr: true });

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale, req, resolvedUrl } = context;

  const localeRes = await serverSideTranslations(locale, ['common']);
  const currentURL = `https://${req.headers.host}${resolvedUrl}`;
  const tempData = localeRes['_nextI18Next']['initialI18nStore'][locale]['common'] ?? {};

  return {
    props: {
      ...localeRes,
      herf: currentURL,
      locale,
      title: tempData[`momsgpts_meta_audio_intro_Title`],
      description: tempData[`momsgpts_meta_audio_intro_Desc`],
    },
  };
};

const Index: NextPageWithLayout<InferGetStaticPropsType<typeof getServerSideProps>> = (props) => {
  return <MainPage data={{ ...props }} />;
};

Index.getLayout = function getLayout(page: React.ReactElement) {
  const title = page?.props?.title;
  const desc = page?.props?.description;

  return (
    <Layout
      head={
        <Head>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={desc} />

          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta property="og:image" content={AppSettings.logoUrl} />
          <meta property="og:image:secure_url" content={AppSettings.logoUrl} />
          <meta property="og:type" content="summary" />
          <meta property="og:url" content={page.props?.herf} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={desc} />
          <meta name="twitter:image" content={AppSettings.logoUrl} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={page.props?.herf} />

          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
          />
          <link rel="canonical" href={page.props?.herf} />
          <link rel="icon" href={AppSettings.icoPath} />
        </Head>
      }
    >
      {page}
    </Layout>
  );
};

export default Index;
